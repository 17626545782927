import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Divider, Form, Header, Segment } from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";
import { createEmail, getAll } from "../features/emails/emailSlice";

const CreateEmail = ({order, setOpen}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { allEmails, isLoading } = useSelector(
    (state) => state.emails
  );

  const [emailData, setEmailData] = useState({
    id: uuidv4(),
    title: "",
    subject: "FL2F - ",
    body: `Dear [recipientName],\n \n [senderName], FL2F Program Assistant \nEmail: theteam@fl2f.ca\nWebsite: https://www.fl2f.ca`,
    order: order,
    sort: 1,
  });

  const getSortValue = () => {
    const orderEmails = allEmails.filter((email) => email.order === order);

    if (orderEmails.length === 0) {
      return 1;
    }

    const sortValues = orderEmails.map((email) => email.sort);
    return Math.max(...sortValues) + 1;
  };

  useEffect(() => {
    setEmailData((prevState) => ({
      ...prevState,
      sort: getSortValue(),
    }));
  }, [allEmails, order]);

  console.log("emailData", emailData);

  const { id, title, subject, body } = emailData;

  const onChange = (e) => {
    setEmailData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // Submit schedule and events
  const onSubmit = (e) => {
    e.preventDefault();
    const canSave = [
      id,
      title,
      subject,
      body,
      // phonenumber, and linkedin are not required
    ].every((el) => el.length >= 1);

    if (allEmails.some((email) => email.title === title)) {
      toast.error("Email Title Already Exists");
      return;
    }

    if (canSave) {
      try {
        dispatch(
          createEmail({
            id,
            title,
            subject,
            body,
            order,
            sort: getSortValue(),
          })
        );
        toast.success("Email successfully created");

        dispatch(getAll());
        navigate(`/emails`);
        setOpen(false);
      } catch (error) {
        const message =
          error.response.data.message || error.message || error.toString();
        toast.error(message);
        console.log(message);
      }
    } else {
      toast.error("All fields are required", {
        position: toast.POSITION.TOP_LEFT,
      });
      console.log("id", id);
      console.log("title", title);
      console.log("subject", subject);
      console.log("body", body);
    }
  };

  return (
    <>
      <Segment loading={isLoading} size="huge" basic padded>
        <Container fluid textAlign="justified">
          <p style={{ fontSize: "16px"}}>
            For dynamically generated emails specific to each person that the
            email is being sent to, please use the following when creating your
            template. <br />
            <br /> [recipientName] - for the Name of the person <br />
            [cohortName] - for the cohort name (usually used in the subject){" "}
            <br />
            [meetingLink] - for the google meet link. Copy this text: &lt;a
            href="[meetingLink]"&gt;[meetingLink]&lt;/a&gt;
            <br />
          </p>
        </Container>
        <Divider />
        <Form size="large" onSubmit={onSubmit}>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              type="text"
              label="Email Title"
              placeholder="Email Title"
              name="title"
              value={title}
              onChange={onChange}
            />
            <Form.Input
              fluid
              type="text"
              label="Subject"
              placeholder="Subject"
              name="subject"
              value={subject}
              onChange={onChange}
            />
          </Form.Group>
          <Form.TextArea
            label="Body"
            placeholder="Body"
            name="body"
            rows={18}
            value={body}
            onChange={onChange}
          />
          <Form.Group widths="equal">
            <Form.Button fluid color="blue" size="big" content="Save" />
          </Form.Group>
        </Form>
      </Segment>
    </>
  );
};
export default CreateEmail;
