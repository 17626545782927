import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, ButtonOr, Container, Divider, Dropdown, Header, Input, Modal, Segment, Table } from "semantic-ui-react";
import DragDrop from "../components/DragDrop";
import TrackerForm from "../components/TrackerForm";
import GeneratePDF from "../components/generatePDF";
import { getAll, sendEmail } from "../features/emails/emailSlice";
import { getAllGroups } from "../features/groups/groupSlice";
import { editWithdrawal, getMembers } from "../features/members/memberSlice";
import { uploadFileToBucket, uploadPDF } from "../features/upload/uploadSlice";

// Import required hooks and components
const DashboardForGroup = () => {
  const dispatch = useDispatch();
  // Access auth state from Redux
  const { user } = useSelector(
    (state) => state.auth
  );

  const { membersArr, isLoading: isLoadingMem } = useSelector((state) => state.members);
  const { allEmails, isLoading: isLoadingMail } = useSelector((state) => state.emails);

  const { groupsArr } = useSelector((state) => state.groups);

  const [selectedMembers, setSelectedMembers] = useState([]);

  const { groupID } = useParams();

  useEffect(() => {
    dispatch(getAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllGroups());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMembers(groupID));
  }, [dispatch, groupID]);


  // Initialize state variables for the component
  const [previewSource, setPreviewSource] = useState(null); // Preview source of the uploaded file
  const [fileType, setFileType] = useState({}); // File type of the uploaded file
  const [currentGroup, setCurrentGroup] = useState(null); // Currently selected group
  const [certificateDate, setCertificateDate] = useState(
    new Date().toISOString().split("T")[0]
  ); // Date of the certificate
  // States for sending emails at the bottom of the dashboard
  const [email, setEmail] = useState(""); // Email address for sending emails
  const [hidden, setHidden] = useState(true); // State to toggle email input visibility
  const [dateHidden, setDateHidden] = useState(true); // State to toggle date input visibility

  // States for the upload document section
  const [uploadState, setUploadState] = useState(false); // State to track whether a document is being uploaded
  // State for the currently displayed member form (if any)
  const [active, setActive] = useState(null);
  // State for showing a spinner during processing
  const [showSpinner, setShowSpinner] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showSelectionModal, setShowSelectionModal] = useState(false);

  // Memoized search for the current group to conditionally render the generate certificate button
  const findGroupRole = useMemo(() => {
    const group = groupsArr.find((group) => group.id === groupID);
    return group || null;
  }, [groupsArr, groupID]);

  // Update the currentGroup state when findGroupRole changes
  useEffect(() => {
    setCurrentGroup(findGroupRole);
  }, [findGroupRole]);

  const handleMemberSelection = (e, { value }) => {
    setSelectedMembers(value);
  };
  
  // Function to handle submitting the email form
  const submitEmails = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    
    console.log("selected", selectedMembers);
    for (const selectedMemberId of selectedMembers) {
      // Find the corresponding member object from membersArr
      const member = membersArr.find((member) => member.id === selectedMemberId);
  
      if (member) {
        try {
          console.log("sending email to", member.name);
          setShowSpinner(true); // Show spinner during email sending process
          await dispatch(
            sendEmail({
              templateId: email.id,
              member: member,
              user: user,
            })
          ).unwrap();
          
          toast.success(`Email Sent To ${member.name}`); // Show success toast
        } catch (error) {
          // Handle any errors during email sending
          const message = error.message || error.toString();
          toast.error(`${member.nam} error:`, message); // Show error toast
          console.log(message); // Log error message
        } finally {
          setShowSelectionModal(false); // Close the modal after email is sent
          setHidden(true); // Reset email input state
          setSelectedMembers([]); // Reset selected members
          setEmail({ id: "", title: "" }); // Reset email state
        }
      }
    }
  };
  
  
  const submitUpload = (e) => {
    e.preventDefault();
    if (previewSource) {
      uploadDoc(previewSource);
    }
  };

  // Function to handle document upload
  const uploadDoc = async (base64Encoded) => {
    setShowSpinner(true); // Show spinner during file upload process

    // Extract the base64 encoded data from the input string
    const splitImage = base64Encoded.split(",")[1];

    try {
      // Dispatch the uploadFileToBucket action with necessary parameters
      await dispatch(
        uploadFileToBucket({
          groupID,
          file: splitImage,
          fileName: fileType.name,
          contentType: fileType.type,
        })
      ).unwrap();
      console.log("File uploaded successfully"); // Log successful upload message
      
      toast.success("File uploaded"); // Show success toast
      
    } catch (error) {
      // Handle any errors during file upload
      console.error(error);
      toast.error("Error uploading file"); // Show error toast
    } finally {
      setShowSpinner(false); // Hide spinner after file is uploaded, regardless of success
      setUploadState(false); // Reset upload state, closing the modal
    }

    
  };

  // used to check is a student has incomplete items
  const isIncomplete = (member) => {
    const incomplete = [
      member.preworkshop,
      member.postworkshop,
      member.followup,
      member.confidentialityConsent,
      member.recordingconsent,
      member.personalmeetings,
      member.document,
    ].some((el) => !el);

    return incomplete;
  };

  console.log("allEmails", allEmails);

  // sorting the email select
  const emailCopy = [...allEmails].sort((a, b) => {
    // First, compare by 'order'
    if (a.order !== b.order) {
      return a.order - b.order;
    }
  
    // If 'order' is the same, compare by 'sort'
    if (a.sort !== b.sort) {
      return a.sort - b.sort;
    }
  
    // If 'sort' is also the same, compare by 'optional'
    if (a.optional !== b.optional) {
      return a.optional - b.optional;
    }
  
    // Finally, if all above properties are the same, sort alphabetically by title
    return a.title.localeCompare(b.title);
  });
  

  let members = membersArr.filter((member) => member.groupid === groupID);

  const generateCert = async () => {
    setShowSpinner(true);

    const date = new Date(certificateDate)
      .toUTCString()
      .split(" ")
      .splice(1, 3)
      .join(" ");
    setTimeout(() => {
      const genForAll = async () => {
        setShowSpinner(true);
        members.filter(members => members.withdrawal !== 1).forEach(async (member) => {
          if (member.role !== "participant" && member.role !== "alumni") {
            toast.error(
              `${member.title} must have participant or alumni role to generate certificate`
            );
          } else {
            GeneratePDF(member, date);
            await dispatch(uploadPDF({ member, date })).unwrap();
            toast.success("Certificate generated and uploaded to Data Bucket");
          }

          
        });
      };
      genForAll();
      setShowSpinner(false);
      setDateHidden(true);
      setShowSelectionModal(false);
    }, 0);
  };

  const onDateChange = (e) => {
    setCertificateDate(e.target.value);
  };

  const handleActive = (id) => {
    active === id ? setActive(null) : setActive(id);
  }

  const editModal = () => {

    if (active === null) return null;

    const member = members.find((member) => member.id === active);

    if(showEditModal === true && active === null ) {
      setShowEditModal(false);
    }

    

    return (
    <>
      <Modal dimmer="blurring" size="small" open={showEditModal}  onClose={() => {setShowEditModal(false); setActive(null) }}>
        <Modal.Header>{member.name} {member.lastname} Details</Modal.Header>
        <Modal.Content>
          <TrackerForm member={member} setShowEditModal={setShowEditModal} active={active} setActive={setActive} />
        </Modal.Content>
        <Modal.Actions>
          <Button size="big" color="black" onClick={() => {setShowEditModal(false); setActive(null) }}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>)
  }

  const closeSelectionModal = () => {

    if (hidden === false) {
      setHidden(true);
      setEmail({ id: "", title: "" });
      setSelectedMembers([]);
    }
    if (dateHidden === false) {
      setDateHidden(true);
    }
    if (uploadState === true) {
      setUploadState(false);
    }
    setShowSelectionModal(false);
  }

  console.log("membersArr", membersArr);

  const selectionModal = () => {

    const emailOptions = emailCopy.map(email => ({
      key: email.id,
      text: email.id.slice(0, 3) + " - " + email.title,
      value: email.id,
    }));

    return (
    <>
      <Modal dimmer="blurring" size="large" open={showSelectionModal}  onClose={closeSelectionModal} >
        {
          !hidden ?
          <Modal.Header>
            Email Selection
          </Modal.Header> : null
        }
        {
          !dateHidden ?
          <Modal.Header>
            Generate Certificates
          </Modal.Header> : null
        }
        {
          uploadState ?
          <Modal.Header>
            Upload Video
          </Modal.Header> : null
        }

        <Modal.Content>
          {
            !hidden ? 
            <>
              <Segment basic size="huge">
                <p>Choose the participants to send the email to, it will be send to the users institutional email</p>
                <Dropdown
                  placeholder="Select Members"
                  size="huge"
                  fluid
                  multiple
                  search
                  selection
                  options={membersArr.filter(member => member.withdrawal === 0).map((member, index) => ({
                    key: index,
                    text: member.name + " " + member.lastname,
                    value: member.id,
                  }))}
                  value={selectedMembers}
                  onChange={handleMemberSelection}
                />
                <Divider />
                <p>Select an email to send</p>
                <Dropdown
                    placeholder="Select an Email to send"
                    fluid
                    selection
                    clearable
                    options={emailOptions}
                    value={email.id}
                    onChange={(e, { value }) => {
                      setEmail({ id: value, title: emailOptions.find(o => o.value === value)?.text });
                    }}
                  />
              </Segment>
            </>
            : null
          }
          {
            !dateHidden ? 
            <>
              <Segment basic size="huge">
                <p>Enter the date for the certificate</p>
                <Input fluid type="date" value={certificateDate} onChange={onDateChange} />
              </Segment>  
            </> : null
          }
          {
            uploadState ? 
            <>
              <Segment basic size="huge">
                <DragDrop
                  previewSource={previewSource}
                  setPreviewSource={setPreviewSource}
                  fileType={fileType}
                  setFileType={setFileType}
                />
              </Segment>
              
            </> : null
          }
        </Modal.Content>
        <Modal.Actions>
          <Button.Group  size="big">
            {
              !hidden ? <Button color="green" loading={isLoadingMail} onClick={submitEmails}  >Send Emails</Button> : null
            }
            {
              !dateHidden ? <Button color="green" loading={showSpinner} onClick={generateCert} >Generate Certificates</Button> : null
            }
            {
              uploadState ? <Button color="green" onClick={submitUpload} >Upload</Button> : null
            }
            <ButtonOr />
            <Button color="black" onClick={closeSelectionModal} >
              Close
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    </>
    )

  }


  const onEditWithdrawal = async (id, withdrawal) => {
    const withdrawalStatus = withdrawal ? 1 : 0;
    
    try {
      await dispatch(editWithdrawal({ id, withdrawal: withdrawalStatus })).unwrap();
      await dispatch(getMembers(groupID)).unwrap();
      toast.success(`Withdrawal status updated for member ID: ${id}`);
    } catch (error) {
      toast.error(`Failed to update withdrawal status: ${error.message}`);
    }
  };
  

  
  return (
    <Container>
      <>
      {user ? (
        <Segment size="huge" padded raised loading={isLoadingMem} style={{ marginTop: "3em" }}>
          <Header  dividing as="h1" textAlign="center">Group Dashboard for {currentGroup?.groupname}</Header>
          <>
            {membersArr.length > 0 ? (
              <>
                <p style={{textAlign: "center"}}>Note: A withdrawn member indicates that they were not able to complete the workshop; they are removed from the email selection and do not appear in the peer assessment and investment panel apps.</p>
                <Table compact padded size="large" textAlign="center" >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>ID</Table.HeaderCell>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>Lastname</Table.HeaderCell>
                      <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {members.map((member, index) => (
                      <Table.Row positive={member.withdrawal === 1 ? false : !isIncomplete(member)} negative={member.withdrawal === 1 ? false : isIncomplete(member)} key={index} >
                        <Table.Cell disabled={member.withdrawal === 1} >{member.id.slice(0, 8)}</Table.Cell>
                        <Table.Cell disabled={member.withdrawal === 1} >{member.name}</Table.Cell>
                        <Table.Cell disabled={member.withdrawal === 1} >{member.lastname}</Table.Cell>
                        <Table.Cell>
                          <Button.Group size="large" fluid>
                            { 
                              member.withdrawal === 1 ? null : <Button  onClick={() => {handleActive(member.id); setShowEditModal(true)}}>Edit</Button>
                            }
                            {
                              currentGroup && (currentGroup.grouprole === "participant" || currentGroup.grouprole === "alumni") && member?.withdrawal !== null ? 
                              <Button onClick={() => onEditWithdrawal(member.id, member.withdrawal === 0)} color={member.withdrawal === 0 ? "red" : "green"}>{member.withdrawal === 0 ? "Withdraw" : "Reinstate"}</Button> : null
                            }
                          </Button.Group>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                  <Table.Footer fullWidth>
                    <Table.Row >
                      <Table.HeaderCell colSpan={5}>
                        <Button.Group fluid >
                          <Button size="large" color="blue" onClick={() => {setHidden(false); setShowSelectionModal(true)}}>Email Selection</Button>
                          {
                            currentGroup && (currentGroup.grouprole === "participant" || currentGroup.grouprole === "alumni") ? 
                            <Button size="large" color="green" onClick={() => {setDateHidden(false); setShowSelectionModal(true)}}>Generate Certicates</Button> : null
                            
                          }
                          <Button size="large" color="teal" onClick={() => {setUploadState(true); setShowSelectionModal(true)}} >Upload Video</Button>
                        </Button.Group>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
                {editModal()}
                {selectionModal()}
              </>
            ) : (
              <h3>No members to show</h3>
            )}
          </>
        </Segment>
      ) : (
        <>
          <h2>You're not logged in</h2>
          <Link to="/login">
            <button className="btn btn-primary btn-block" type="submit">
              Please Login
            </button>
          </Link>
        </>
      )}
      </> 
    </Container>
  );
};
export default DashboardForGroup;
