

export const Applicationform = {
    "pages" : [
    {
        "name": "contact_info",
        "title": "Contact Information",
        "elements": [
            {
            "type": "text",
            "name": "name",
            "title": "Name",
            "isRequired": true,
            },
            {
            "type": "text",
            "name": "lastname",
            "startWithNewLine": false,
            "title": "Last Name",
            "isRequired": true,
            },
            {
            "type": "text",
            "name": "phonenumber",
            "startWithNewLine": false,
            "title": "Phone Number",
            "placeHolder": "Please enter in the format xxx xxx xxxx with spaces.",
            "validators": [{
                "type": "regex",
                "text": "Please enter a valid canadian phone number",
                "regex": "\\d{3} \\d{3} \\d{4}"
            }]
            },            
            {
            "type": "text",
            "name": "email",
            "title": "Gmail Address",
            "isRequired": true,
            //add a validador for only gmail emails
            "validators": [{
                "type": "regex",
                "text": "Please enter a valid gmail email address",
                "regex": "^[a-zA-Z0-9_.+-]+@gmail.com+$"
            }]
            },
            {
                "type": "text",
                "name": "instemail",
                "startWithNewLine": false,
                "title": "Institutional/Company Email Address",
                "isRequired": true,
            },
            {
                "type": "text",
                "name": "linkedin",
                "title": "LinkedIn Profile",
            },
            {
                "type": "dropdown",
                "name": "country",
                "title": "Country",
                "isRequired": true,
                "choices": ["Canada", "United States", "Mexico"],
                "startWithNewLine": false,
                "showOtherItem": true,
                "otherText": "Other"
            },
        
        ],
    },
    {
        "name": "personal_info",
        "title": "Personal Information",
        "elements": [
            {
            "type": "dropdown",
            "name": "edulevel",
            "title": "Highest Level of Education",
            "isRequired": true,
            "choices": ["Bachelor's Degree", "Master's Degree", "PhD"],
            },
            {
                "type": "radiogroup",
                "name": "specialization",
                "title": "Area of Specialization",
                "isRequired": true,
                "colCount": 1,
                "choices": [
                    "Science",
                    "Technology",
                    "Engineering",
                    "Mathematics",
                    "Medicine",
                    "Health Sciences"
                ],
                "showClearButton": true,
                "showOtherItem": true,
                "otherText": "Other (please specify)"
            },
            {
                "type": "text",
                "name": "academicInstitution",
                "title": "Are you associated with any academic institution?",
                "description": "Please write the name of your academic institution if yes, please leave blank if not."
            },
            {
            "type": "dropdown",
            "name": "eduPosition",
            "title": "Please state your position",
            "isRequired": true,
            "visibleIf": "{academicInstitution} notempty",
            "showOtherItem": true,
            "otherText": "Other (please specify)",
            "choices": ["PhD Student before Candidacy", "PhD Student after Candidacy","PostDoctoral Fellowship", "Faculty"],
            },
            {
                "type": "text",
                "name": "companyOrganization",
                "title": "Are you affiliated with any company or organization?",
                "description": "Please write the name of your company or organization and please state the title of your position (i.e. founder, co-founder, employee, consultant, etc)"
            },
            {
                "type": "boolean",
                "name": "patentIdea",
                "title": "Do you have an idea that you are pondering on patenting and commercializing?",
                "valueTrue": "Yes",
                "valueFalse": "No",
                "defaultValue": "No",

            },
            {
                "type": "text",
                "name": "patentIdeaName",
                "visibleIf": "{patentIdea} = Yes",
                "title": "Please outline the title of the idea you would like to explore in the workshop.",
            },
            {
                "type": "radioGroup",
                "name": "patentIdeaStage",
                "visibleIf": "{patentIdea} = Yes",
                "title": "What stage are you in your idea?",
                "colCount": 1,
                "choices": [
                    "Idea only",
                    "Preliminary research",
                    "Patent filed",
                    "Initial funding receieved"
                ],
                "showClearButton": true,
                "showOtherItem": true,
                "otherText": "Other (please specify)"
            },
            {
                "type":"boolean",
                "name":"receivingNotice",
                "titleLocation":"hidden",
                "label":"Are you interested in receiving notification about future workshops if you are not admitted into this one?",
                "renderAs":"checkbox",
                "valueTrue":1,
                "valueFalse":0,
                "defaultValue":0
            }
        
        ],
    },
    {
        "name": "acknowledgements",
        "title": "Acknowledgements",
        "elements": [
            {
            "type": "checkbox",
            "name": "confidentialityConsent",
            "title": "As you will be working on an idea you want to commercialize and protection of intellectual property is of utmost importance, you will be required to sign a confidentiality agreement with the other members of the group (each participant will be signing for the other participants). Please confirm your acknowledgment.",
            "titleLocation": "left",
            "isRequired": true,
            "colCount": 1,
            "choices": ["I accept"],
            },
            {
                "type": "checkbox",
                "name": "recordingConsent",
                "title": "During the From Lab 2 Fulfillment (FL2F) program we need your consent for any photos or recordings of you taken during the workshops to be used for promotion and training purposes. You will be required to sign a consent agreement before the start of the workshop. Please confirm your acknowledgment.",
                "titleLocation": "left",
                "isRequired": true,
                "colCount": 1,
                "choices": ["I accept"],
            },
            {
                "type": "radiogroup",
                "name": "collaborationConsent",
                "title": "Workshop learning emphasizes experiential, small group activities including personal tasks within and between sessions. Are you prepared to contribute and collaborate with other group members by sharing your time, strengths, and resources. If not, please note any concerns/limits, you may have. This is to ensure that we are aware of any obstacles or needs.",
                "titleLocation": "left",
                "showOtherItem": true,
                "isRequired": true,
                "colCount": 1,
                "choices": ["Yes"]
            },
            {
                "type": "html",
                "name": "info",
                "html": "<p style='font-weight: 600;'>Your time commitment is important for your success in the workshop. Please check the box below to acknowledge that you have looked at the schedule (see <a href='https://www.fl2f.ca/learn-more' target='_blank' rel='noopener noreferrer'>Schedule</a>) and that you will be able to attend the online scheduled group and individual meetings. Additionally, you understand that there are videos to be watched and homework to be completed after each group meeting which can take 1-3 hours to complete.</p>"
            },
            {
                "type": "checkbox",
                "name": "commitmentConsent",
                "titleLocation": "hidden",
                "isRequired": true,
                "colCount": 1,
                "choices": ["I accept"],
                "startWithNewLine": false,
                "showCommentArea": true,
                "commentText": "Please specify the date when you will be unavailable."
            },
            
            {
                "type": "html",
                "name": "info",
                "html": "<p style='font-weight: 600;'>I have read through the eligibility requirements, and I am aware of the cost, payment deadlines, and refund policy (see <a href='https://www.fl2f.ca/learn-more' target='_blank' rel='noopener noreferrer'>Learn More</a>).</p>"
            },
            {
                "type": "checkbox",
                "name": "eligibilityConsent",
                "titleLocation": "hidden",
                "isRequired": true,
                "colCount": 1,
                "choices": ["I understand"],
                "startWithNewLine": false,
            }
        ],
    }
    
    ],
    "showQuestionNumbers": false,
    "showProgressBar": "top",
    "progressBarType": "requiredQuestions",
    "showPreviewBeforeComplete": "showAnsweredQuestions",
    "maxOthersLength": 100,
    "allowResizeComments": false,

};