import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Container, Header, Icon, Menu, Message, Modal, Segment, Table } from "semantic-ui-react";
import CreateEmail from "../components/CreateEmail";
import { getAll, setActiveItem, updateSort } from "../features/emails/emailSlice";
import "../styles/groupSelect.scss";

// Emails component
const Emails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { allEmails, isLoading, activeItem } = useSelector((state) => state.emails); // Access emails state from Redux

  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState(1);
  const [organizedEmails, setOrganizedEmails] = useState({});
  const [optionalEmails, setOptionalEmails] = useState({});
  const [initialEmails, setInitialEmails] = useState({});
  const [editingRow, setEditingRow] = useState(null);
  const [editingTableType, setEditingTableType] = useState(null);
  const [highlightedEmailId, setHighlightedEmailId] = useState(null);

  useEffect(() => {
    dispatch(getAll());
  }, [dispatch]);

  useEffect(() => {
    if (allEmails) {
      const organized = organizeEmails(allEmails);
      setOrganizedEmails(organized.organizedEmails);
      setOptionalEmails(organized.optionalEmails);
      setInitialEmails(organized.organizedEmails);
    }
  }, [allEmails]);

  useEffect(() => {
    const hash = location.hash.substring(1);
    if (hash) {
      const emailElement = document.getElementById(hash);
      if (!emailElement) {
        findAndSetActiveItem(hash);
      } else {
        setHighlightedEmailId(hash);
        emailElement.scrollIntoView({ behavior: "smooth" });
        setTimeout(() => setHighlightedEmailId(null), 1500);
      }
    }
  }, [location, organizedEmails, optionalEmails]);

  const findAndSetActiveItem = (emailId) => {
    const sections = ['beforeworkshop', 'duringworkshop', 'afterworkshop', 'miscellaneous'];
    for (const section of sections) {
      if (organizedEmails[section]?.some(email => email.id === emailId) || optionalEmails[section]?.some(email => email.id === emailId)) {
        switch (section) {
          case 'beforeworkshop':
            dispatch(setActiveItem('Before Workshop'));
            setOrder(1);
            break;
          case 'duringworkshop':
            dispatch(setActiveItem('During Workshop'));
            setOrder(2);
            break;
          case 'afterworkshop':
            dispatch(setActiveItem('After Workshop'));
            setOrder(3);
            break;
          case 'miscellaneous':
            dispatch(setActiveItem('Miscellaneous'));
            setOrder(4);
            break;
          default:
            setOrder(1);
        }
        setTimeout(() => {
          const emailElement = document.getElementById(emailId);
          if (emailElement) {
            setHighlightedEmailId(emailId);
            emailElement.scrollIntoView({ behavior: "smooth" });
            setTimeout(() => setHighlightedEmailId(null), 1500);
          }
        }, 100); // Give time for the UI to update
        break;
      }
    }
  };

  const sortEmails = (emails) => {
    return emails.sort((a, b) => {
      const aSort = a.sort || 0;
      const bSort = b.sort || 0;
      return aSort - bSort;
    });
  };

  const organizeEmails = (emails) => {
    const organizedEmails = {
      beforeworkshop: [],
      duringworkshop: [],
      afterworkshop: [],
      miscellaneous: [],
    };

    const optionalEmails = {
      beforeworkshop: [],
      duringworkshop: [],
      afterworkshop: [],
      miscellaneous: [],
    };

    emails.forEach((email) => {
      const target = email.optional ? optionalEmails : organizedEmails;
      switch (email.order) {
        case 1:
          target.beforeworkshop.push(email);
          break;
        case 2:
          target.duringworkshop.push(email);
          break;
        case 3:
          target.afterworkshop.push(email);
          break;
        case 4:
          target.miscellaneous.push(email);
          break;
        default:
          break;
      }
    });

    for (const key in organizedEmails) {
      if (organizedEmails[key].length > 0) {
        organizedEmails[key] = sortEmails(organizedEmails[key]);
      }
      if (optionalEmails[key].length > 0) {
        optionalEmails[key] = sortEmails(optionalEmails[key]);
      }
    }

    return { organizedEmails, optionalEmails };
  };

  const handleItemClick = (e, { name }) => {
    dispatch(setActiveItem(name));
    switch (name) {
      case "Before Workshop":
        setOrder(1);
        break;
      case "During Workshop":
        setOrder(2);
        break;
      case "After Workshop":
        setOrder(3);
        break;
      case "Miscellaneous":
        setOrder(4);
        break;
      default:
        setOrder(1);
    }
  };

  const saveOrder = async () => {
    const key = activeItem.toLowerCase().replace(" ", "");
    const updatedEmails = [
      ...organizedEmails[key],
      ...optionalEmails[key],
    ].map((email, index) => ({ ...email, sort: index + 1 }));

    try {
      await dispatch(updateSort(updatedEmails)).unwrap();
      toast.success("Order saved successfully");
      dispatch(getAll()); // Re-fetch emails to ensure state is updated
      setEditingRow(null);
      setEditingTableType(null);
    } catch (error) {
      toast.error("Error saving order");
    }
  };

  const handleMoveUp = (emails, index) => {
    if (index > 0) {
      const newEmails = [...emails];
      const temp = newEmails[index - 1];
      newEmails[index - 1] = newEmails[index];
      newEmails[index] = temp;
      return newEmails;
    }
    return emails;
  };

  const handleMoveDown = (emails, index) => {
    if (index < emails.length - 1) {
      const newEmails = [...emails];
      const temp = newEmails[index + 1];
      newEmails[index + 1] = newEmails[index];
      newEmails[index] = temp;
      return newEmails;
    }
    return emails;
  };

  const handleEditRow = (emailId, tableType) => {
    setEditingRow(emailId);
    setEditingTableType(tableType);
  };

  const handleCancelEdit = () => {
    setOrganizedEmails(initialEmails);
    setEditingRow(null);
    setEditingTableType(null);
  };

  const handleEmailMove = (key, index, direction, type) => {
    const targetEmails = type === "organized" ? organizedEmails[key] : optionalEmails[key];
    const newEmails = direction === "up" ? handleMoveUp(targetEmails, index) : handleMoveDown(targetEmails, index);

    if (type === "organized") {
      setOrganizedEmails({ ...organizedEmails, [key]: newEmails });
    } else {
      setOptionalEmails({ ...optionalEmails, [key]: newEmails });
    }
  };

  const renderContent = () => {
    const key = activeItem.toLowerCase().replace(" ", "");
    return (
      <>
        {renderTable(organizedEmails[key], key, "organized")}
        {optionalEmails[key]?.length > 0 && (
          <>
            <Header as="h3">Optional Emails</Header>
            {renderTable(optionalEmails[key], key, "optional")}
          </>
        )}
      </>
    );
  };

  const renderTable = (emails, tableKey, type) => (
    <Table stackable celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            {editingRow && editingTableType === type && (
              <>
                <Button.Group size="small">
                  <Button title="Cancel Row Selection" icon="cancel" color="black" onClick={handleCancelEdit} />
                  <Button title="Save" color="orange" icon="save" onClick={saveOrder} />
                </Button.Group>
              </>
            )}
          </Table.HeaderCell>
          <Table.HeaderCell>Title</Table.HeaderCell>
          <Table.HeaderCell>Subject</Table.HeaderCell>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>
            <Button.Group fluid size="large" compact>
              {type === "organized" && <Button color="green" onClick={() => setOpen(true)}>New Email</Button>}
            </Button.Group>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {emails?.map((email, index) => (
          <Fragment key={email.id}>
            <Table.Row
              key={email.id}
              id={email.id}
              active={editingRow === email.id || highlightedEmailId === email.id}
            >
              <Table.Cell singleLine textAlign="center" onClick={() => handleEditRow(email.id, type)}>
                {editingRow === email.id && editingTableType === type ? (
                  <>
                    <Button.Group size="small" vertical>
                      <Button icon="arrow up" onClick={() => handleEmailMove(tableKey, index, "up", type)} />
                      <Button icon="arrow down" onClick={() => handleEmailMove(tableKey, index, "down", type)} />
                    </Button.Group>
                  </>
                ) : (
                  <>
                    <Icon fitted name="ellipsis vertical" />
                    <Icon fitted name="ellipsis vertical" />
                  </>
                )}
              </Table.Cell>
              <Table.Cell title={email.title}>{email.title}</Table.Cell>
              <Table.Cell title={email.subject}>{email.subject}</Table.Cell>
              <Table.Cell style={{fontWeight: "bold"}}  title={email.id}>{email.id.slice(0,3)}</Table.Cell>
              <Table.Cell>
                <Button.Group fluid size="small">
                  <Button color="blue" onClick={() => navigate(`/emails/${email.id}`)}>Edit</Button>
                  <Button.Or />
                  <Button color="red" onClick={() => navigate(`/confirm/${email.id}`)}>Delete</Button>
                </Button.Group>
              </Table.Cell>
            </Table.Row>
          </Fragment>
        ))}
      </Table.Body>
    </Table>
  );

  // Return the component JSX
  return (
    <Container>
      <Segment size="large" basic padded loading={isLoading} style={{ marginTop: "1.5em" }}>
        <Header as="h1" textAlign="center">Emails
          <Header.Subheader>View and manage all emails here.</Header.Subheader>
        </Header>
        <Segment>
          <Message info>
            <Message.Header>Instructions</Message.Header>
            <Message.List>
              <Message.Item>Each section below contains emails that should be sent out at different times during the workshop.</Message.Item>
              <Message.Item>Optional Emails are not always necessary to send out, it depends on the workshop.</Message.Item>
            </Message.List>
          </Message>
          <Menu size="huge" stackable fluid widths={4}>
            <Menu.Item name="Before Workshop" active={activeItem === "Before Workshop"} onClick={handleItemClick} disabled={editingRow !== null ? true : false} />
            <Menu.Item name="During Workshop" active={activeItem === "During Workshop"} onClick={handleItemClick} disabled={editingRow !== null ? true : false} />
            <Menu.Item name="After Workshop" active={activeItem === "After Workshop"} onClick={handleItemClick} disabled={editingRow !== null ? true : false} />
            <Menu.Item name="Miscellaneous" active={activeItem === "Miscellaneous"} onClick={handleItemClick} disabled={editingRow !== null ? true : false} />
          </Menu>
        </Segment>
        <Segment>{renderContent()}</Segment>
      </Segment>
      <Modal closeIcon open={open} onClose={() => setOpen(false)} closeOnDimmerClick={false} closeOnEscape={false} size="fullscreen">
        <Modal.Header>Create New Email</Modal.Header>
        <Modal.Content>
          <CreateEmail order={order} setOpen={setOpen} />
        </Modal.Content>
      </Modal>
    </Container>
  );
};

export default Emails;
