import { DateTime } from "luxon";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GroupItem from "../components/GroupItem";
import { getAllGroups } from "../features/groups/groupSlice";
import { getAll } from "../features/members/memberSlice";
import "../styles/groupSelect.scss";

import { Header, Segment, Table } from "semantic-ui-react";

const GroupSelect = () => {
  const dispatch = useDispatch();

  const { groupsArr, isLoading } = useSelector((state) => state.groups);


  useEffect(() => {
    dispatch(getAllGroups());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAll());
  }, [dispatch]);

 
  const groups = [...groupsArr];

  const roleOrder = ["participant", "guest", "alumni", "admin", "facilitator"];
  

  // Sort groups by start date and role order
  groups.sort((a, b) => {
    if (a.startdate < b.startdate) {
      return 1;
    } else if (a.startdate > b.startdate) {
      return -1;
    } else {
      return roleOrder.indexOf(a.grouprole) - roleOrder.indexOf(b.grouprole);
    }
  });

  return (
    <div className="appContainer">
      <main className="body-container">
        <Header textAlign="center" as="h1">FL2F Operations Tracker</Header>
        <Segment raised compact secondary loading={isLoading} size='huge'>
          <section>
            {groupsArr.length > 0 ? (
              <Table textAlign="center" compact size="large">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Group Name</Table.HeaderCell>
                    <Table.HeaderCell>Start Date</Table.HeaderCell>
                    <Table.HeaderCell>Role</Table.HeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                {groups.map(
                  (group, index) =>
                    (group.grouprole === "participant" ||
                      group.grouprole === "alumni") && (
                      <GroupItem
                        key={index}
                        id={group.id}
                        group={group.groupname}
                        role={group.grouprole}
                        startdate={group.startdate}
                      />
                    )
                )}
                </Table.Body>
              </Table>
            ) : (
              <Header as="h2" textAlign="center">
                No groups found
              </Header>
            )}
          </section>
        </Segment>
      </main>
    </div>
  );
};
export default GroupSelect;
