import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Container, Divider, Dropdown, Grid, Header, Message, Modal, Segment, Table } from "semantic-ui-react";
import { getAll, sendEmailApp } from "../features/emails/emailSlice";
import { getAllGroups } from "../features/groups/groupSlice";
import { getApplications, getConsent } from "../features/survey/surveySlice";

const Applications = () => {
    const dispatch = useDispatch()

    const { applications, consent,  isLoading } = useSelector(state => state.surveys)

    const { groupsArr, isLoading: isLoadingGrp } = useSelector(state => state.groups);

    const { user } = useSelector(state => state.auth)

    const [showSelectionModal, setShowSelectionModal] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)
    const [selectedApplicants, setSelectedApplicants] = useState([])
    const [email, setEmail] = useState({ id: "", title: "" })
    const [selectedGroup, setSelectedGroup] = useState(null) //indicates the selected group by appcode

    const { allEmails } = useSelector((state) => state.emails);

    useEffect(() => {
        dispatch(getApplications())
    }, [dispatch])

    useEffect(() => {
        dispatch(getAll())
    }, [dispatch])

    useEffect(() => {
        dispatch(getConsent())
    }, [dispatch])

    useEffect(() => {
        dispatch(getAllGroups())
    }, [dispatch])

    const groups = [...groupsArr].sort((a, b) => new Date(b.startdate.split('T')[0]) - new Date(a.startdate.split('T')[0]))

    //remove applicants in the applications array that have already consented and filter by selected group with appcode
    const applicationsFiltered = applications.filter(application => !consent.some(consent => consent.data.id === application.data.id) && application.data.code === selectedGroup)

    const consentFiltered = consent.filter(application => application.data.code === selectedGroup)

    const emailCopy = [...allEmails].sort((a, b) => {
        //regex to get numbers before and after the decimal
        const regex = /^(\d+)(\.\d+)?\s*/;
        const aMatch = a.title.match(regex);
        const bMatch = b.title.match(regex);
        //if both have a number at th ebeginnning and have a number after the decimal
        if (aMatch && bMatch) {
          return (
            parseInt(aMatch[1], 10) +
            parseFloat(aMatch[2] || 0) -
            (parseInt(bMatch[1], 10) + parseFloat(bMatch[2] || 0))
          );
          // else if the first email have a number
        } else if (aMatch) {
          return -1;
          // else if the second email has a number
        } else if (bMatch) {
          return 1;
          //finally, if neither have a number, sort alphabetically.
        } else {
          return a.title.localeCompare(b.title);
        }
    });

    const submitEmails = async (e) => {
        e.preventDefault();
        setShowSpinner(true);
        console.log("selected", selectedApplicants);
        for (const applicantid of selectedApplicants ) {
          // Find the corresponding applicant object
          const applicant = applications.find((application) => application.data.id === applicantid);
      
          if (applicant) {
            try {
                console.log("sending email to", applicant.data.name);
                
                await dispatch(
                sendEmailApp({
                    templateId: email.id,
                    applicant: applicant.data,
                    user: user,
                })
                ).unwrap();
                toast.success(`Email sent to ${applicant.participant}`);
            } catch (error) {
                // Handle any errors during email sending
                const message = error.message || error.toString();
                toast.error(`${applicant.data.name} error:`, message);
                console.log(message);
            } finally {
                setShowSpinner(false);
                setShowSelectionModal(false);
                setSelectedApplicants([]);
                setEmail({ id: "", title: "" }); 
            }
          }
        }
      };

    const closeSelectionModal = () => {
        setEmail({ id: "", title: "" });
        setSelectedApplicants([]);
        setShowSelectionModal(false);
    }

    const selectionModal = () => {

        const emailOptions = emailCopy.map(email => ({
          key: email.id,
          text: email.title,
          value: email.id,
        }));

        const selectedApplicants = applications.filter(application => application.data.code === selectedGroup)
    
        return (
        <>
          <Modal size="large" open={showSelectionModal}  onClose={closeSelectionModal} >
            <Modal.Header>
                Email Selection
            </Modal.Header>
            <Modal.Content>
              {
                <Segment basic size="huge" loading={showSpinner}>
                  <Dropdown
                      placeholder="Select applicants"
                      fluid
                      multiple
                      search
                      selection
                      compact
                      options={selectedApplicants.map((application) => ({
                          key: application.data.id,
                          text: `${application.data.name} ${application.data.lastname} - ${application.data.instemail}`,
                          value: application.data.id,
                      }))}
                      value={selectedApplicants}
                      onChange={(e, { value }) => { setSelectedApplicants(value); }}
                  />
                  <Divider />
                  
                  <Dropdown
                      placeholder="Select an Email"
                      fluid
                      selection
                      clearable
                      compact
                      options={emailOptions}
                      value={email.id}
                      onChange={(e, { value }) => {
                        setEmail({ id: value, title: emailOptions.find(o => o.value === value)?.text });
                      }}
                    />
                </Segment>
                
                
              }
            </Modal.Content>
            <Modal.Actions>
              <Button.Group size="big">
                <Button color="green" onClick={submitEmails} >Send Emails</Button> : null
                
                <Button.Or />
                <Button color="black" onClick={closeSelectionModal} >
                  Close
                </Button>
              </Button.Group>
            </Modal.Actions>
          </Modal>
        </>
        )
    }
    
    //generate a link to apply for the workshop by appcode and adding to the clipboard
    const handleApplyLink = () => {
        const link = `survey.fl2f.ca/apply/${selectedGroup}`
        navigator.clipboard.writeText(link)
        toast.success("Link copied to clipboard")
    }

    return (
      <Container>
        <Segment loading={isLoading} basic style={{ marginTop: "3em" }}>
          <Header  textAlign="center" as="h1">Applications Tracker
            <Header.Subheader>View and manage applications for the workshop, here are all the applications and see their status</Header.Subheader>
          </Header>
          <Segment>
            <Grid columns={2} stackable textAlign="center" verticalAlign="middle">
              <Grid.Column>
                <Dropdown
                  placeholder="Select Group"
                  fluid
                  selection
                  options={groups.map(group => ({
                    key: group.id,
                    text: group.groupname,
                    value: group.appcode,
                  }))}
                  value={selectedGroup}
                  onChange={(e, { value }) => setSelectedGroup(value)}
                />
              </Grid.Column>
              <Grid.Column>
                <Button.Group fluid size='large' >
                    <Button color='blue' onClick={() => setShowSelectionModal(true)}>Send Email</Button>
                    <Button color='green' disabled={selectedGroup === null} onClick={handleApplyLink}>Generate Apply Link</Button>
                </Button.Group>
              </Grid.Column>
            </Grid>
          </Segment>
          <Segment size="large">
          {applicationsFiltered.length === 0 ? (
            selectedGroup ? ( <Message warning content="No applications available for the selected group." />) : ( <Message info content="Select a group to view applications." /> )
          ) : (
            <Segment basic>
              <Header dividing as="h4" textAlign="center">Participants who have sign the consent survey.</Header>
              <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Participant</Table.HeaderCell>
                        <Table.HeaderCell>Gmail Address</Table.HeaderCell>
                        <Table.HeaderCell>Institutional Email</Table.HeaderCell>
                        <Table.HeaderCell>Applied At</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {consentFiltered.map(application => (
                        <Table.Row key={application.data.id}>
                            <Table.Cell singleLine >{application.participant}</Table.Cell>
                            <Table.Cell>{application.data.email.split('@')[0]}</Table.Cell>
                            <Table.Cell>{application.data.instemail}</Table.Cell>
                            <Table.Cell>{new Date(application.data.datetimeApplied).toLocaleString('en-US', { dateStyle: 'full', timeStyle: 'short' })}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
                </Table>
              <Divider />
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Applicant</Table.HeaderCell>
                    <Table.HeaderCell>Gmail Address</Table.HeaderCell>
                    <Table.HeaderCell>Institutional Email</Table.HeaderCell>
                    <Table.HeaderCell>Applied At</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {applicationsFiltered.map(application => (
                    <Table.Row key={application.data.id}>
                      <Table.Cell singleLine >{application.participant}</Table.Cell>
                      <Table.Cell>{application.data.email.split('@')[0]}</Table.Cell>
                      <Table.Cell>{application.data.instemail}</Table.Cell>
                      <Table.Cell>{new Date(application.data.datetimeApplied).toLocaleString('en-US', { dateStyle: 'full', timeStyle: 'short' })}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Segment>
            )}
          </Segment>
        </Segment>
        {selectionModal()}
      </Container>
    )
}

export default Applications