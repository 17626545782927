import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Confirm from "./components/Confirm";
import Navbar from "./components/Navbar";
import PrivateRoutes from "./features/utils/PrivateRoutes";
import Applications from "./pages/Applications";
import DashboardForGroup from "./pages/DashboardForGroup";
import EditEmail from "./pages/EditEmail";
import Emails from "./pages/Emails";
import ErrorPage from "./pages/ErrorPage";
import GenerateGroupPhoto from "./pages/GenerateGroupPhoto";
import GroupSelect from "./pages/GroupSelect";
import Linkedin from "./pages/Linkedin";
import Login from "./pages/Login";
import SpiderGraphs from "./pages/SpiderGraphs";
import Surveys from "./pages/Surveys";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoutes />}> {/* Wrap protected routes inside PrivateRoutes */}
            <Route path="/" element={<GroupSelect />} />
            <Route path="/linkedin" element={<Linkedin />} />
            <Route path="/graphs" element={<SpiderGraphs />} />
            <Route path="/groupPhoto" element={<GenerateGroupPhoto />} />
            <Route path="/surveys" element={<Surveys />} />
            <Route path="/applications" element={<Applications />} />
            <Route path="/emails" element={<Emails />} />
            <Route path="/emails/:emailID" element={<EditEmail />} />
            <Route path="/confirm/:emailID" element={<Confirm />} />
            <Route path="/:groupID" element={<DashboardForGroup />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;

