import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Container, Divider, Form, Header, Segment } from "semantic-ui-react";
import Spinner from "../components/Spinner";
import { logout } from "../features/auth/authSlice";
import { getAllGroups } from "../features/groups/groupSlice";
import { getAll } from "../features/members/memberSlice";
import "../styles/linkedIn.scss";
const Linkedin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allMembers, isLoading, isError, message } = useSelector(
    (state) => state.members
  );
  const { groupsArr } = useSelector((state) => state.groups);
  useEffect(() => {
    if (isError) toast.error(message);

    if (!allMembers.length) {
      dispatch(getAll());
    }

    if (!groupsArr.length) {
      dispatch(getAllGroups());
    }
  }, [allMembers, groupsArr, isError, message, dispatch, navigate]);

  const [groupID, setGroupID] = useState("");
  const [personsOfContact, setPersonsOfContact] = useState("");
  const [guests, setGuests] = useState("");
  const [showDraft, setShowDraft] = useState(false);
  const [ceremonyDate, setCeremonyDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  let groups = groupsArr.filter((group) => !["admin", "facilitator"].includes(group.role));

  const onSubmit = (e) => {
    setShowDraft(!showDraft);
  };

  const handleReset = () => {
    setGroupID("");
    setPersonsOfContact("");
    setGuests("");
    setShowDraft(false);
    setCeremonyDate(new Date().toISOString().split("T")[0]);
  };

  const getGroupMembers = () => {
    return allMembers.filter(member => member.groupid === groupID);
  };

  let groupMembers = getGroupMembers();

  let getGroupNames = () => {
    let names = groupMembers.map(member => member.name ? "@" + member.name + member.lastname : member.username);
    return names.join(", ");
  };

  const getText = () => {
    
    let draftText = `We’re excited to share that we have successfully completed another @From Lab 2 Fulfillment workshop on ${ceremonyDate}! \n\n`;

    if (personsOfContact.length > 0) {
      draftText += `A huge thanks to @${personsOfContact} for helping us make this workshop possible. \n\n`;
    }

    draftText += `We can’t forget to thank the lovely ladies who attended our workshop! ${getGroupNames()} - thank you so much for your curiosity, engagement, and your significant investment of time and effort over the course of the workshop. We are confident it will pay off, and so look forward to seeing where entrepreneurship will take you! \n\n`;

    if (guests.length > 0) {
      draftText += `Special thanks to our special guests, ${guests}. \n\n`;
    }

    draftText += `#WomenEntrepreneurs #WomenInSTEM #WomenInHealth #EntrepreneurshipEducation #Innovation`;

    return draftText;
  }

  const copyToClipboard = async () => {
    try {
      const text = getText();
      await navigator.clipboard.writeText(text);
      toast.success("Text copied to clipboard");
    }
    catch (error) {
      console.error("Failed to copy text to clipboard", error);
      toast.error("Failed to copy text to clipboard");
    }
  }


  return (
    <Container>
      <Segment loading={isLoading} size="huge" raised padded>
        <Header dividing as="h2" textAlign="center">Linkedin Post - Draft Generation
        <Header.Subheader>Select a group and click submit to generate the text for a draft linkedin post. You may still want to edit the text to add more specifics afterward.</Header.Subheader>
        </Header>
        <Form size="large" onSubmit={onSubmit}>
          <Form.Group widths="equal">
            <Form.Select
              type="text"
              label="Select Group"
              placeholder="Select Group"
              options={groups.map((group) => ({
                key: group.id,
                text: group.groupname,
                value: group.id,
              }))}
              onChange={(e, { value }) => setGroupID(value)}
              value={groupID}
              disabled={showDraft}
              required
            />
            <Form.Input
              label="Ceremony Date"
              placeholder="Ceremony Date"
              value={ceremonyDate}
              onChange={(e) => setCeremonyDate(e.target.value)}
              disabled={showDraft}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label="Persons of Contact"
              placeholder="Persons of Contact"
              value={personsOfContact}
              onChange={(e) => setPersonsOfContact(e.target.value)}
              disabled={showDraft}
            />
            <Form.Input
              label="Guests"
              placeholder="Guests"
              value={guests}
              onChange={(e) => setGuests(e.target.value)}
              disabled={showDraft}
            />
          </Form.Group>
          {
            groupID && (
              <Form.Button disabled={showDraft} fluid color="blue" size="big" content="Submit" />
            )
          }
        </Form>
        <Divider hidden={!showDraft} />
        {
          showDraft && (
            <Segment basic>
              <Header textAlign="center" as="h3">Draft Linkedin Post</Header>
              <Container fluid textAlign="justified">
                <p>
                  We’re excited to share that we have successfully completed
                  another @From Lab 2 Fulfillment workshop on {ceremonyDate}!
                  <br />
                  <br />
                  {personsOfContact.length > 0 ? (
                    <p>
                      A huge thanks to @{personsOfContact} for helping us make
                      this workshop possible.
                      <br />
                      <br />
                    </p>
                  ) : (
                    <p></p>
                  )}
                  We can’t forget to thank the lovely ladies who attended our
                  workshop! {getGroupNames()} - thank you so much for your
                  curiosity, engagement, and your significant investment of time
                  and effort over the course of the workshop. We are confident it
                  will pay off, and so look forward to seeing where
                  entrepreneurship will take you!
                  <br />
                  <br />
                  {guests.length > 0 ? (
                    <p>
                      Special thanks to our special guests, {guests}.
                      <br />
                      <br />
                    </p>
                  ) : (
                    <p></p>
                  )}
                  #WomenEntrepreneurs #WomenInSTEM #WomenInHealth
                  #EntrepreneurshipEducation #Innovation
                </p>
              </Container>
              <Divider/>
              <Button.Group fluid size="big">
                <Button color="green" content="Copy to Clipboard" onClick={copyToClipboard} />
                <Button color="red" content="Reset" onClick={handleReset} />
              </Button.Group>
            </Segment>
          )
        }
      </Segment>
    </Container>
  );
};
export default Linkedin;
