import axios from "axios";

let API_URL = "";
if (process.env.NODE_ENV !== "production") {
  API_URL = "http://localhost:5000/api/upload/"; // lets us use the backend server in local development
}
else {
  API_URL = "/api/";
}

// /api/upload/certificate
// used to upload Certificate to Data Bucket
const uploadPDF = async (PDFData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await axios.post(API_URL + "certificate", PDFData, config);
  return data;
};

// Route: POST /api/upload/video
// used to upload video to Data Bucket
const uploadFileToBucket = async (docData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await axios.post(API_URL + "video", docData, config);
  return data;
};

const generateGraphs = async (docData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await axios.post(API_URL + "generateGraphs", docData, config);
  return data;
};

const uploadService = {
  uploadPDF,
  uploadFileToBucket,
  generateGraphs,
};

export default uploadService;
