import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, ButtonOr, Container, Divider, Dropdown, Grid, Icon, Input, Segment, Table } from "semantic-ui-react";
import GeneratePDF from "../components/generatePDF";
import { sendEmail } from "../features/emails/emailSlice";
import { editMember, getMembers } from "../features/members/memberSlice";
import { uploadPDF } from "../features/upload/uploadSlice";
import "../styles/trackerForm.scss";
import Spinner from "./Spinner";

const TrackerForm = ({ member, active, setActive, setShowEditModal }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const { groupID } = useParams();

  const { allEmails, isLoading } = useSelector((state) => state.emails);

  console.log(member);

  const [formValues, setFormValues] = useState({
    preworkshop: member.preworkshop,
    postworkshop: member.postworkshop,
    followup: member.followup,
    confidentialityConsent: member.confidentialityConsent,
    recordingconsent: member.recordingconsent,
    personalmeetings: member.personalmeetings,
    document: member.document,
    id: member.id,
  });

  const {
    preworkshop,
    postworkshop,
    followup,
    confidentialityConsent,
    recordingconsent,
    personalmeetings,
    document,
    id,
  } = formValues;

  console.log(member);

  //these are for the sending of emails at the bottom of the dashboard
  const [email, setEmail] = useState({
    id: "",
    title: "",
  });

  const [hidden, setHidden] = useState(true);
  const [dateHidden, setDateHidden] = useState(true);
  const [certificateDate, setCertificateDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [showSpinner, setShowSpinner] = useState(false);

  // submit send email for individual member
  const submitEmail = async (e) => {
    e.preventDefault();

    const canSave = [
      email.id,
      member.instemail,
      member.name,
      member.groupid,
    ].every((el) => el.length >= 1);



    if (canSave) {
      try {
        await dispatch(
          sendEmail({
            templateId: email.id,
            member: member,
            user: user,
          })
        ).unwrap();
        setHidden(true);
        setActive(null);
        setShowEditModal(false);
        setEmail({
          id: "",
          title: "",
        });
        toast.success("Email Sent");
      } catch (error) {
        const message = error.message || error.toString();
        toast.error(message);
        console.log(message);
      }
    } else {
      toast.error("Missing information");
      console.log(member.email, member.name, member.groupid);
    }
  };

  

  // update user info
  const submitUpdate = async () =>
    await dispatch(
      editMember({
        preworkshop,
        postworkshop,
        followup,
        confidentialityConsent,
        recordingconsent,
        personalmeetings,
        document,
        id,
      })
    ).unwrap();


  // submit for tracker form for member
  const onSubmit = (e) => {
    e.preventDefault();
    try {
      // console.log("oneOnOneAccess :>> ", oneOnOneAccess);
      submitUpdate();
      dispatch(getMembers(groupID));
      toast.success("Member successfully updated");

      setActive(null);
      setShowEditModal(false);
    } catch (error) {
      const message =
        error.response.data.message || error.message || error.toString();
      toast.error(message);
      console.log(message);
    }
  };

  // click for checkboxes
  const handleFormGroupClick = (fieldName) => {
    console.log(fieldName + " clicked");
    setFormValues({
      ...formValues,
      [fieldName]: formValues[fieldName] ? 0 : 1,
    });
  };

  const onDateChange = (e) => {
    setCertificateDate(e.target.value);
  };

  const generateCert = async () => {
    setShowSpinner(true);

    const date = new Date(certificateDate)
      .toUTCString()
      .split(" ")
      .splice(1, 3)
      .join(" ");
    const genForAll = async () => {
      setShowSpinner(true);
      GeneratePDF(member, date);
    };
    genForAll();
    await dispatch(uploadPDF({ member, date })).unwrap();

    setShowSpinner(false);
    toast.success("Certificate generated and uploaded to Data Bucket");
    setActive(null);
    setShowEditModal(false);
    setHidden(true);
    setDateHidden(true);
  };

  // sorting the email select
  const emailCopy = [...allEmails].sort((a, b) => {
    // First, compare by 'order'
    if (a.order !== b.order) {
      return a.order - b.order;
    }
  
    // If 'order' is the same, compare by 'sort'
    if (a.sort !== b.sort) {
      return a.sort - b.sort;
    }
  
    // If 'sort' is also the same, compare by 'optional'
    if (a.optional !== b.optional) {
      return a.optional - b.optional;
    }
  
    // Finally, if all above properties are the same, sort alphabetically by title
    return a.title.localeCompare(b.title);
  });

  const emailOptions = emailCopy.map(email => ({
    key: email.id,
    text: email.title,
    value: email.id,
  }));



  return (
    <Segment loading={showSpinner} size="large" basic >
      <Grid columns={1} stretched >
        <Grid.Row>
          <Grid.Column>
            <Table singleLine padded collapsing size="large" definition >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell/>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Completed Pre-Workshop Survey?</Table.Cell>
                  <Table.Cell textAlign="center">
                    {
                      formValues.preworkshop === 1 ?
                      <Icon fitted name="checkmark" color="green" size="large" />
                      : <Icon fitted name="close" color="red" size="large" />
                    }
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Completed Post-Workshop Survey?</Table.Cell>
                  <Table.Cell textAlign="center">
                    {
                      formValues.postworkshop === 1 ?
                      <Icon fitted name="checkmark" color="green" size="large" />
                      : <Icon fitted name="close" color="red" size="large" />
                    }
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Completed Follow-Up Survey?</Table.Cell>
                  <Table.Cell textAlign="center">
                    {
                      formValues.followup === 1 ?
                      <Icon fitted name="checkmark" color="green" size="large" />
                      : <Icon fitted name="close" color="red" size="large" />
                    }
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Confidentiality Form Accepted?</Table.Cell>
                  <Table.Cell onClick={() => handleFormGroupClick("confidentialityConsent")} selectable textAlign="center">
                    {
                      formValues.confidentialityConsent === 1 ?
                      <Icon fitted name="checkmark" color="green" size="large" />
                      : <Icon fitted name="close" color="red" size="large" />
                    }
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Recording Consent?</Table.Cell>
                  <Table.Cell onClick={() => handleFormGroupClick("recordingconsent")} selectable textAlign="center">
                    {
                      formValues.recordingconsent === 1 ?
                      <Icon fitted name="checkmark" color="green" size="large" />
                      : <Icon fitted name="close" color="red" size="large" />
                    }
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>One-on-One Personal Meeting Access?</Table.Cell>
                  <Table.Cell onClick={() => handleFormGroupClick("personalmeetings")} selectable textAlign="center">
                    {
                      formValues.personalmeetings === 1 ?
                      <Icon fitted name="checkmark" color="green" size="large" />
                      : <Icon fitted name="close" color="red" size="large" />
                    }
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Dan/Dana Role</Table.Cell>
                  <Table.Cell >
                    <Dropdown
                      fluid
                      selection
                      options={[
                        { key: "dan", text: "Dan", value: "dan" },
                        { key: "dana", text: "Dana", value: "dana" },
                      ]}
                      value={document}
                      onChange={(e, { value }) => setFormValues({ ...formValues, document: value })}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            
            {/* Buttons */}
            <>
              {
                hidden && dateHidden && (
                  <>
                    <Button.Group fluid size="big">
                      <Button
                        color="blue"
                        onClick={() => setHidden(false)}
                        disabled={!hidden || !dateHidden}
                        content="Select Email"
                      />
                      <Button
                        color="teal"
                        disabled={!hidden || !dateHidden}
                        onClick={() => setDateHidden(false)}
                        content="Generate Certificate"
                      />
                    </Button.Group>
                    <Divider />
                    <Button
                      color="green"
                      size="big"
                      fluid
                      onClick={onSubmit}
                      loading={showSpinner}
                      hidden={!hidden || !dateHidden}
                      content="Submit Changes"
                    />
                  </>
                )
              }
            </>
            <>
              <Segment size="big" hidden={hidden}>
                <Dropdown
                  placeholder="Select an Email to send"
                  fluid
                  selection
                  clearable
                  options={emailOptions}
                  value={email.id}
                  onChange={(e, { value }) => {
                    setEmail({ id: value, title: emailOptions.find(o => o.value === value)?.text });
                  }}
                />
                <Divider />
                <Button.Group size="big" fluid>
                  <Button
                    color="green"
                    onClick={submitEmail}
                    loading={isLoading}
                    content="Send"
                  />
                  <ButtonOr />
                  <Button
                    color="red"
                    onClick={() => {setHidden(true); setEmail({ id: "", title: "" });}}
                    content="Cancel"
                  />
                </Button.Group>
              </Segment>
              
              {member.role === "participant" || member.role === "alumni" ? (
                <>
                  <Segment size="big" hidden={dateHidden}>
                    {/* Input for certificate date */}
                    <Input fluid type="date" value={certificateDate} onChange={onDateChange} />
                    <Divider />
                    <Button.Group size="big" fluid >
                      <Button
                        color="green"
                        onClick={generateCert}
                        content="Generate"
                      />
                      <ButtonOr />
                      <Button
                        color="red"
                        onClick={() => setDateHidden(true)}
                        content="Cancel"
                      />
                    </Button.Group>
                  </Segment>
                </>
              ) : null}
            </>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>     
  );
};

export default TrackerForm;