import axios from "axios";

let API_URL = "";
if (process.env.NODE_ENV !== "production") {
  API_URL = "http://localhost:5000/api/groups/"; // lets us use the backend server in local development
}
else {
  API_URL = "/api/groups/";
}

// GET all members
const getAllGroups = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await axios.get(API_URL, config);
  return data;
};

// POST generate photo
// We send to backend to avoid CORS issues with cloud functions
const genGroupPhoto = async (groupData, token) => {
  console.log("groupData", groupData);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await axios.post(API_URL + "generate",  groupData, config);
  return data.URL;
};

// PUT update the bottomText for a group for groupPhoto
const updateBottomText = async (groupData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { groupID } = groupData;
  const { data } = await axios.put(API_URL + groupID, groupData, config);
  return data;
};

const memberService = { getAllGroups, genGroupPhoto, updateBottomText };

export default memberService;
