// Import required libraries and components
import { Link } from "react-router-dom";

// ErrorPage component
const ErrorPage = () => {
  // Return the component JSX
  return (
    <div className="body-container">
      <h2>404! Page Not Found!</h2>
      // Link component is used to navigate to the home page
      <Link to="/">
        // Button to navigate back to the home page
        <button className="btn btn-block btn-primary">Go Back</button>
      </Link>
    </div>
  );
};
export default ErrorPage;

// In this code, we have an ErrorPage component that displays a 404 error message to indicate that the requested page was not found.

// The Link component from react-router-dom is used to provide navigation to the home page. A "Go Back" button is rendered inside the Link component, allowing users to return to the home page by clicking on the button.

// The JSX returned by the component contains a div with the error message and the navigation button.