import React from 'react';
import { Segment } from 'semantic-ui-react';
import { Model, Survey } from 'survey-react-ui';
import { theme } from '../surveys/theme/theme';

const SurveyTab = ({ survey, form }) => {

    const surveyModel = new Model(form);
    surveyModel.applyTheme(theme);
    surveyModel.mode = 'display';
    surveyModel.data = survey.data;

    return (
        <Segment basic>
            <Survey model={surveyModel} />
        </Segment>
    );
};

export default SurveyTab;
