import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dropdown, Icon, Image, Menu, Sidebar } from 'semantic-ui-react';
import logo from "../assets/FL2F-logo.png";
import { logout, reset } from "../features/auth/authSlice";
import { setActiveItem } from "../features/menu/menuSlice";
import "../styles/header.scss";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { activeItem } = useSelector((state) => state.menu);
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const handleActiveItem = (e, { name }) => dispatch(setActiveItem(name));

  useEffect(() => {
    const pathToNameMapping = {
      '/': 'Home', // Assuming you want to highlight "Home" when at the root
      '/applications': 'Applications',
      '/emails': 'Emails',
      '/surveys': 'Surveys',
      '/linkedin': 'Linkedln',
      '/groupPhoto': 'Group Photo',
      '/graphs': 'Graphs',
    };

    const activeItemName = pathToNameMapping[location.pathname] || 'Home';
    dispatch(setActiveItem(activeItemName));
  }, [location, dispatch]);

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/login");
  };

  const toggleSidebar = () => setSidebarVisible(!sidebarVisible);

  if (location.pathname === "/login") return null;

  

  return (
    <>
      <Menu size="huge" className="desktop-menu">
        <Menu.Item as={Link} to="/" onClick={handleActiveItem}>
          <Image src={logo} size="small" />
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item as={Link} to="/applications" name="Applications" active={activeItem === 'Applications'} onClick={handleActiveItem}>
            Applications
          </Menu.Item>
          <Menu.Item as={Link} to="/emails" name='Emails' active={activeItem === 'Emails'} onClick={handleActiveItem}>
            Emails
          </Menu.Item>
          <Menu.Item as={Link} to="/surveys" name="Surveys" active={activeItem === 'Surveys'} onClick={handleActiveItem}>
            Surveys
          </Menu.Item>
          {/* add dropdown menu for linkedin and group photo and graphs */}
          <Dropdown item text='Tools'>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/linkedin" name="Linkedln" active={activeItem === 'Linkedln'} onClick={handleActiveItem}>
                LinkedIn
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/groupPhoto" name="Group Photo" active={activeItem === 'Group Photo'} onClick={handleActiveItem}>
                Group Photo
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/graphs" name="Graphs" active={activeItem === 'Graphs'} onClick={handleActiveItem}>
                Graphs
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Item name="Logout" onClick={onLogout}>
            Logout
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      {/* The mobile menu is a Sidebar component from Semantic UI React. */}
      {/* Will only be shown if the screen is less than or equal to 768px */}
      <Menu size="huge" className="mobile-menu">
        <Menu.Item as={Link} to="/" onClick={handleActiveItem}>
          <Image src={logo} size="small" />
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item onClick={toggleSidebar}>
            <Icon name="sidebar" size="large" />
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        vertical
        visible={sidebarVisible}
        onHide={() => setSidebarVisible(false)}
        direction="right"
      >
        <Menu.Item as={Link} to="/applications" name="Applications" active={activeItem === 'Applications'} onClick={handleActiveItem}>
          Applications
        </Menu.Item>
        <Menu.Item as={Link} to="/emails" name='Emails' active={activeItem === 'Emails'} onClick={handleActiveItem}>
          Emails
        </Menu.Item>
        <Menu.Item as={Link} to="/surveys" name="Surveys" active={activeItem === 'Surveys'} onClick={handleActiveItem}>
          Surveys
        </Menu.Item>
        <Menu.Item as={Link} to="/linkedin" name="Linkedln" active={activeItem === 'Linkedln'} onClick={handleActiveItem}>
          LinkedIn
        </Menu.Item>
        <Menu.Item as={Link} to="/groupPhoto" name="Group Photo" active={activeItem === 'Group Photo'} onClick={handleActiveItem}>
          Group Photo
        </Menu.Item>
        <Menu.Item as={Link} to="/graphs" name="Graphs" active={activeItem === 'Graphs'} onClick={handleActiveItem}>
          Graphs
        </Menu.Item>
        <Menu.Item name="Logout" onClick={onLogout}>
          Logout
        </Menu.Item>
      </Sidebar>
    </>
  );
};

export default Navbar;
