import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Divider, Form, Header, Segment } from "semantic-ui-react";
import { editEmail, getAll } from "../features/emails/emailSlice";

const EditEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { allEmails, isError, isLoading, message } = useSelector(
    (state) => state.emails
  );

  const { emailID } = useParams();

  let email = allEmails.find((email) => email.id === emailID);

  const [emailData, setEmailData] = useState("");

  useEffect(() => {
    if (isError) toast.error(message);

    if (allEmails.length === 0) {
      dispatch(getAll());
    }

    if (email) {
      setEmailData(email);
    }
  }, [email, allEmails, user, isError, message, navigate, dispatch]);


  const { id, title, subject, body } = emailData;

  const onChange = (e) => {
    setEmailData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // Submit schedule and events
  const onSubmit = (e) => {
    e.preventDefault();
    const canSave = [
      id,
      title,
      subject,
      body,
      // phonenumber, and linkedin are not required
    ].every((el) => el.length >= 1);

    if (canSave) {
      try {
        dispatch(
          editEmail({
            id,
            title,
            subject,
            body,
          })
        );
        toast.success("Email successfully updated");

        dispatch(getAll());
        navigate(`/emails`);
      } catch (error) {
        const message =
          error.response.data.message || error.message || error.toString();
        toast.error(message);
        console.log(message);
      }
    } else {
      toast.error("All fields are required", {
        position: toast.POSITION.TOP_LEFT,
      });
      console.log("id", id);
      console.log("title", title);
      console.log("subject", subject);
      console.log("body", body);
    }
  };

  return (
    <Container>
      <Segment loading={isLoading} size="huge" raised padded>
        <Header textAlign="center" as="h2">Edit Email {emailData.title}</Header>
        <Container fluid textAlign="justified">
          <p style={{ fontSize: "16px"}}>
            For dynamically generated emails specific to each person that the
            email is being sent to, please use the following when creating your
            template. <br />
            <br /> [recipientName] - for the Name of the person <br />
            [cohortName] - for the cohort name (usually used in the subject){" "}
            <br />
            [meetingLink] - for the google meet link. Copy this text: &lt;a
            href="[meetingLink]"&gt;[meetingLink]&lt;/a&gt;
            <br />
            <br />
            Also, the img tag at the bottom is the FL2F logo. Please do not remove
            that when creating a new email
          </p>
        </Container>
        <Divider />
        <Form size="large" onSubmit={onSubmit}>
          <Form.Input
            fluid
            label="Email Title"
            placeholder="Email Title"
            name="title"
            value={title}
            onChange={onChange}
          />
          <Form.Input
            fluid
            label="Subject"
            placeholder="Subject"
            name="subject"
            value={subject}
            onChange={onChange}
          />
          <Form.TextArea
            label="Body"
            placeholder="Body"
            name="body"
            rows={12}
            value={body}
            onChange={onChange}
          />
          <Form.Group widths="equal">
            <Form.Button fluid color="blue" size="big" content="Save" />
            <Form.Button fluid color="red" size="big" content="Cancel" onClick={() => navigate(`/emails`)} />
          </Form.Group>
        </Form>
      </Segment>
    </Container>
  );
};
export default EditEmail;
