import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Container, Divider, Dropdown, Grid, Header, Image, Segment } from "semantic-ui-react";
import { getAllGroups } from "../features/groups/groupSlice";

const SpiderGraphs = () => {

    const dispatch = useDispatch();

    const { groupsArr, isLoading} = useSelector((state) => state.groups);

    useEffect(() => {
        dispatch(getAllGroups());
    }, [dispatch]);

    const groups = [...groupsArr].filter(group => group.grouprole !== "admin" && group.grouprole !== "facilitators" && group.grouprole !== "guest" ).sort((a, b) => a.groupname.localeCompare(b.groupname));


    return (
        <Container>
            <Segment size="large" basic padded style={{ marginTop: "1.5em" }}>
                <Header as="h1" textAlign="center">Spider Graphs</Header>
                <Segment size="large" padded>
                    <Dropdown
                        placeholder="Select Group"
                        fluid
                        selection
                        options={groups.map(group => ({
                            key: group.id,
                            text: group.groupname,
                            value: group.id
                        }))}
                    />
                    <Divider section />
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Header as="h3" textAlign="center">
                                    Selected Group Spider Graph
                                </Header>
                                <Image src="path_to_selected_group_spider_graph_image" size="large" centered />
                                <Divider />
                                <Button.Group fluid>
                                    <Button disabled={groups.length === 0}>Download</Button>
                                    <Button disabled={groups.length === 0}>Generate</Button>
                                </Button.Group>
                            </Grid.Column>
                            <Grid.Column>
                                <Header as="h3" textAlign="center">
                                    All Groups Spider Graphs
                                </Header>
                                <Image src="path_to_all_groups_spider_graph_image" size="large" centered />
                                <Divider />
                                <Button.Group fluid>
                                    <Button>Download</Button>
                                    <Button>Generate</Button>
                                </Button.Group>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Segment>
        </Container>
    )
}

export default SpiderGraphs