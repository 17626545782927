import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Divider, Dropdown, Grid, Header, Icon, Menu, Message, Modal, Segment, Tab } from "semantic-ui-react";
import 'survey-core/defaultV2.min.css';
import SurveyTab from "../components/SurveyTab";
import { getAllGroups } from "../features/groups/groupSlice";
import { getApplications, getConsent, getFollowup, getPostworkshop, getPreworkshop } from "../features/survey/surveySlice";
import { Applicationform } from "../surveys/application/ApplicationFrom";
import { ConsentForm } from '../surveys/application/ConsentForm';
import { Followupform } from "../surveys/workshop/FollowupForm";
import { Postworkshopform } from "../surveys/workshop/PostworkshopForm";
import { Preworkshopform } from "../surveys/workshop/PreworkshopForm";

const Surveys = () => {
    const dispatch = useDispatch();
    const [activeSurvey, setActiveSurvey] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(6);

    const { applications, consent, preworkshop, postworkshop, followup, isLoading } = useSelector(state => state.surveys);

    const { groupsArr, isLoading: isLoadingGrp } = useSelector(state => state.groups);

    useEffect(() => {
        dispatch(getAllGroups());
    }, [dispatch]);

    const groups = [...groupsArr].sort((a, b) => new Date(b.startdate.split('T')[0]) - new Date(a.startdate.split('T')[0]))

    const [selectedGroup, setSelectedGroup] = useState(null);
    const [sortedSurveys, setSortedSurveys] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        let surveys = [];
        switch (activeSurvey) {
            case "Applications":
                surveys = [...applications].filter(survey => selectedGroup ? survey.data.code === selectedGroup : true).sort((a, b) => new Date(a.data.datetimeApplied) - new Date(b.data.datetimeApplied))
                break;
            case "Preworkshop":
                surveys = [...preworkshop].filter(survey => selectedGroup ? survey.groupid === selectedGroup : true);
                break;
            case "Postworkshop":
                surveys = [...postworkshop].filter(survey => selectedGroup ? survey.groupid === selectedGroup : true);
                break;
            case "Followup":
                surveys = [...followup].filter(survey => selectedGroup ? survey.groupid === selectedGroup : true);
                break;
            case "Consent":
                surveys = [...consent].filter(survey => selectedGroup ? survey.data.code === selectedGroup : true);
                break;
            default:
                break;
        }
        setSortedSurveys(surveys);
        setTotalPages(Math.ceil(surveys.length / itemsPerPage));
        setCurrentPage(1); // Reset to the first page when survey type changes
    }, [activeSurvey, applications, consent, preworkshop, postworkshop, followup, itemsPerPage, selectedGroup]);

    const currentSurveys = sortedSurveys.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const getItemsPerPage = () => {
        //get width of the survey segment
        const width = document.querySelector(".survey").clientWidth;
        if (width <= 375) {
            setItemsPerPage(2);
        } else if (width <= 768) {
            setItemsPerPage(3);
        } else if (width <= 1024) {
            setItemsPerPage(4);
        } else {
            setItemsPerPage(6);
        }
    };

    useEffect(() => {
        getItemsPerPage();
        window.addEventListener("resize", getItemsPerPage);
        return () => window.removeEventListener("resize", getItemsPerPage);
    }, []);


    const handleMenuClick = (name) => {
        switch (name) {
            case "Applications":
                if (applications.length === 0) {
                    dispatch(getApplications());
                }
                break;
            case "Preworkshop":
                if (preworkshop.length === 0) {
                    dispatch(getPreworkshop());
                }
                break;
            case "Postworkshop":
                if (postworkshop.length === 0) {
                    dispatch(getPostworkshop());
                }
                break;
            case "Followup":
                if (followup.length === 0) {
                    dispatch(getFollowup());
                }
                break;
            case "Consent":
                if (consent.length === 0) {
                    dispatch(getConsent());
                }
                break;
            default:
                break;
        }
        setActiveSurvey(name);
    };

    const renderSurveyPanes = () => {
        let form;
        switch (activeSurvey) {
            case "Applications":
                form = Applicationform;
                break;
            case "Preworkshop":
                form = Preworkshopform;
                break;
            case "Postworkshop":
                form = Postworkshopform;
                break;
            case "Followup":
                form = Followupform;
                break;
            case "Consent":
                form = ConsentForm;
                break;
            default:
                return [];
        }

        return currentSurveys.map((survey, index) => ({
            menuItem: survey.participant,
            render: () => (
                <Tab.Pane key={index}>
                    <SurveyTab survey={survey} form={form} />
                </Tab.Pane>
            ),
        }));
    };

    return (
        <Container>
            <Segment loading={isLoading} basic style={{ marginTop: "3em" }}>
                <Header textAlign="center" as="h1">Surveys Viewer
                    <Header.Subheader>View and manage surveys, surveys are sorted by datetime applied</Header.Subheader>
                </Header>
                <Segment>
                    <Menu size="huge" stackable fluid widths={5}>
                        <Menu.Item
                            name="Applications"
                            active={activeSurvey === "Applications"}
                            onClick={() => handleMenuClick("Applications")}
                        >
                            Applications
                        </Menu.Item>
                        <Menu.Item
                            name="Consent"
                            active={activeSurvey === "Consent"}
                            onClick={() => handleMenuClick("Consent")}
                        >
                            Consent
                        </Menu.Item>
                        <Menu.Item
                            name="Preworkshop"
                            active={activeSurvey === "Preworkshop"}
                            onClick={() => handleMenuClick("Preworkshop")}
                        >
                            Preworkshop
                        </Menu.Item>
                        <Menu.Item
                            name="Postworkshop"
                            active={activeSurvey === "Postworkshop"}
                            onClick={() => handleMenuClick("Postworkshop")}
                        >
                            Postworkshop
                        </Menu.Item>
                        <Menu.Item
                            name="Followup"
                            active={activeSurvey === "Followup"}
                            onClick={() => handleMenuClick("Followup")}
                        >
                            Followup
                        </Menu.Item>
                    </Menu>
                </Segment>
                {
                    activeSurvey && (
                        <Segment>
                            <Grid columns={3} stackable textAlign="center" verticalAlign="middle">
                                <Grid.Column width={8}>
                                <Menu fluid  pagination widths={3}>
                                    <Menu.Item
                                        disabled={currentPage === 1}
                                        onClick={() => setCurrentPage(currentPage - 1)}
                                    >
                                        Previous
                                    </Menu.Item>
                                    <Menu.Item>
                                        Page {currentPage} of {totalPages}
                                    </Menu.Item>
                                    <Menu.Item
                                        disabled={currentPage === totalPages || currentSurveys.length < itemsPerPage}
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                    >
                                        Next
                                    </Menu.Item>
                                </Menu>
                                </Grid.Column>
                                <Grid.Column width={1}>
                                    <Menu fluid widths={1} icon stackable>
                                        <Menu.Item title="Full Screen" onClick={() => setIsModalOpen(true)}>
                                            <Icon name="expand" fitted  />
                                        </Menu.Item>
                                    </Menu>
                                </Grid.Column>
                                <Grid.Column textAlign="right" width={7}>
                                    <Dropdown
                                        selection
                                        fluid
                                        clearable
                                        options={groups.map(group => ({
                                            key: group.id,
                                            text: group.groupname,
                                            value: activeSurvey === "Applications" || activeSurvey === "Consent" ? group.appcode : group.id
                                        }))}
                                        placeholder="Select Group, if not selected all groups will be shown"
                                        onChange={(e, { value }) => setSelectedGroup(value)}
                                        value={selectedGroup}
                                    />
                                </Grid.Column>
                            </Grid>
                        </Segment>
                    )
                }
                <Segment className="survey">
                {
                    activeSurvey ?
                        currentSurveys.length > 0 ?
                            <Tab panes={renderSurveyPanes()} /> :
                            <Message warning content="No surveys available for the selected group." />
                        :
                        <Message info content="Select a survey type to view." />
                }
                </Segment>

                {/* Full Screen Modal */}
                <Modal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    closeIcon
                    size="fullscreen"
                >
                    <Modal.Header>Full Screen Survey View
                        <Menu pagination style={{ marginLeft: 10}}>
                            <Menu.Item
                                disabled={currentPage === 1}
                                onClick={() => setCurrentPage(currentPage - 1)}
                            >
                                Previous
                            </Menu.Item>
                            <Menu.Item>
                                Page {currentPage} of {totalPages}
                            </Menu.Item>
                            <Menu.Item
                                disabled={currentPage === totalPages || currentSurveys.length < itemsPerPage}
                                onClick={() => setCurrentPage(currentPage + 1)}
                            >
                                Next
                            </Menu.Item>
                        </Menu>
                    </Modal.Header>
                    <Modal.Content>
                        {
                            activeSurvey ?
                                currentSurveys.length > 0 ?
                                    <Tab panes={renderSurveyPanes()} /> :
                                    <Message warning content="No surveys available for the selected group." />
                                :
                                <Message info content="Select a survey type to view." />
                        }
                    </Modal.Content>
                </Modal>


            </Segment>
        </Container>
    );
};

export default Surveys;
