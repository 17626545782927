import { useRef } from "react";
import { toast } from "react-toastify";
import { Button, Container, Header, Icon, Image, Segment } from "semantic-ui-react";
import "../styles/dragDrop.scss";

const DragDrop = ({previewSource, setPreviewSource, fileType, setFileType}) => {
  const inputRef = useRef();

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
    };
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (
      file.type.includes("video") ||
      file.type.includes("application") ||
      file.type.includes("text")
    ) {
      if (file.size > 5000000000) {
        return toast.error("File size must not be greater than 5 GB");
      }

      previewFile(file);
      setFileType(file);
    } else {
      toast.error(
        "Invalid file type. Please add .mov, .mp4, .txt, .pdf, or .docx"
      );
    }
  };

  const handleSelect = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (
      file.type.includes("video") ||
      file.type.includes("application") ||
      file.type.includes("text")
    ) {
      if (file.size > 5000000000) {
        return toast.error("File size must not be greater than 5 GB");
      }
      previewFile(file);
      setFileType(file);
    } else {
      toast.error(
        "Invalid file type. Please add .mov, .mp4, .txt, .pdf, or .docx"
      );
    }
  };

  return (
    <Container style={{width: "500px"}}>
      {
        previewSource ? (
          <Segment>
            {
              fileType.type.includes("video") ? (
                <Segment>
                  <video
                    src={previewSource}
                    width="100%"
                    height="auto"
                    controls
                  />
                  <Button onClick={() => setPreviewSource(null)}>Remove</Button>
                </Segment>
              ) : (
                <Segment>
                  <Image src={previewSource} size="medium" />
                  <Button onClick={() => setPreviewSource(null)}>Remove</Button>
                </Segment>
              )
            }
            
          </Segment>
          
        ) : (
          <div onDragOver={handleDragOver} onDrop={handleDrop}>
            <Segment placeholder>
              <Header as="h2" icon>
                <Icon name="file video outline" />
                Drag and drop a video here
                <Header.Subheader>or select a video from your computer</Header.Subheader>
              </Header>
              <input
                type="file"
                ref={inputRef}
                accept="video/mp4, video/quicktime"
                style={{ display: "none" }}
                onChange={handleSelect}
                hidden
              />
              <Button type="button" size="big" onClick={() => inputRef.current.click()}>Select Video</Button>
            </Segment>
          </div>
        )
      
      }
    </Container>
  );
};
export default DragDrop;
