

export const ConsentForm = {
    "pages" : [
    {
        "name": "contact_info",
        "title": "Contact Information",
        "elements": [
            {
            "type": "text",
            "name": "name",
            "title": "Name",
            "isRequired": true,
            "validators": [{
                "type": "regex",
                "text": "Please enter a valid name",
                "regex": "^[a-zA-ZÀ-ÖØ-öø-ÿ'-\\s]+$",

            }]
            },
            {
            "type": "text",
            "name": "lastname",
            "startWithNewLine": false,
            "title": "Last Name",
            "isRequired": true,
            "validators": [{
                "type": "regex",
                "text": "Please enter a valid name",
                "regex": "^[a-zA-ZÀ-ÖØ-öø-ÿ'-\\s]+$",
            }]},
            {
            "type": "text",
            "name": "phonenumber",
            "startWithNewLine": false,
            "title": "Phone Number",
            "placeHolder": "Please enter in the format xxx xxx xxxx with spaces.",
            "isRequired": true,
            "validators": [{
                "type": "regex",
                "text": "Please enter a valid canadian phone number",
                "regex": "\\d{3} \\d{3} \\d{4}"
            }]
            },            
            {
            "type": "text",
            "name": "email",
            "title": "Gmail Address",
            "isRequired": true,
            //add a validador for only gmail emails
            "validators": [{
                "type": "regex",
                "text": "Please enter a valid gmail email address",
                "regex": "^[a-zA-Z0-9_.+-]+@gmail.com+$"
            }]
            },
            {
                "type": "text",
                "name": "instemail",
                "startWithNewLine": false,
                "title": "Institutional/Company Email Address",
                "isRequired": true,
            },
            {
                "type": "text",
                "name": "linkedin",
                "title": "LinkedIn Profile",
                "isRequired": true,
                "description": "If you do not have a LinkedIn profile, please write N/A",
            },
            {
                "type": "dropdown",
                "name": "country",
                "title": "Country",
                "isRequired": true,
                "choices": ["Canada", "United States", "Mexico"],
                "startWithNewLine": false,
                "showOtherItem": true,
                "otherText": "Other"
            },
        
        ],
    },
    {
        "name": "personal_info",
        "title": "Personal Information",
        "elements": [
            {
                "type": "dropdown",
                "name": "edulevel",
                "title": "Highest Level of Education",
                "isRequired": true,
                "choices": ["Bachelor's Degree", "Master's Degree", "PhD"],
            },
            {
                "type": "text",
                "name": "academicInstitution",
                "title": "Are you associated with any academic institution?",
                "description": "Please write the name of your academic institution if yes, please write N/A if not.",
                "isRequired": true,
            },
            {
                "type": "dropdown",
                "name": "eduPosition",
                "title": "Please state your position",
                "isRequired": true,
                "showOtherItem": true,
                "otherText": "Other (please specify)",
                "choices": ["PhD Student before Candidacy", "PhD Student after Candidacy","PostDoctoral Fellowship", "Faculty"],
            },
            {
                "type": "text",
                "name": "patentIdeaName",
                "title": "Please outline the title of the idea you would like to explore in the workshop.",
                "description": "Please provide a brief description of your idea. Write N/A if you do not have an idea yet.",
                "isRequired": true,
            },
            {
                "type": "radioGroup",
                "name": "patentIdeaStage",
                "title": "What stage are you in your idea?",
                "colCount": 1,
                "choices": [
                    "Idea only",
                    "Preliminary research",
                    "Patent filed",
                    "Initial funding receieved"
                ],
                "showClearButton": true,
                "showOtherItem": true,
                "otherText": "Other (please specify)",
                "isRequired": true,
            },
            {
                "type":"boolean",
                "name":"receivingNotice",
                "titleLocation":"hidden",
                "label":"Are you interested in receiving notification about future workshops if you are not admitted into this one?",
                "renderAs":"checkbox",
                "valueTrue":1,
                "valueFalse":0,
                "defaultValue":0

            }
        
        ],
    },
    {
        "name": "acknowledgements",
        "title": "Acknowledgements",
        "elements": [
            {
                "type":"panel",
                "name":"confidentialityPanel",
                "elements": [
                    {
                        "type": "checkbox",
                        "name": "confidentialityConsent",
                        "title": "As you will be working on an idea you want to commercialize and protection of intellectual property is of utmost importance, you hereby agree to keep confidentiality with respect to the other workshop members and their ideas (each participant will be signing for the other participants). Please confirm your acknowledgement.",
                        "isRequired": true,
                        "colCount": 1,
                        "choices": ["I accept"],
                    },
                    {
                        "type": "signaturepad",
                        "name": "signatureconfidentiality",
                        "title": "Please sign here to certify your acknowledgment",
                        "isRequired": true,
                        "storeDataAsText": false,
                        "waitForUpload": true,
                        "signatureAutoScaleEnabled": true,
                        
                    }
                ]

            },

            {
                "type": "panel",
                "name": "recordingsPanel",
                "elements": [
                    {
                        "type": "checkbox",
                        "name": "recordingConsent",
                        "title": "During the From Lab 2 Fulfillment (FL2F) program we will be taking photos and recordings of you. You hereby consent that these photos and recordings can be used for promotion and training purposes. Please confirm your acknowledgement.",
                        "isRequired": true,
                        "colCount": 1,
                        "choices": ["I accept"],
                    },
                    {
                        "type": "signaturepad",
                        "name": "signaturerecording",
                        "title": "Please sign here to certify your acknowledgment",
                        "isRequired": true,
                        "storeDataAsText": false,
                        "waitForUpload": true,
                        "signatureAutoScaleEnabled": true,
                        
                    }
                ]
            }
        ],
    }
    
    ],
    "showQuestionNumbers": false,
    "showProgressBar": "top",
    "progressBarType": "requiredQuestions",
    "showPreviewBeforeComplete": "showAnsweredQuestions",
    "maxOthersLength": 100,
    "allowResizeComments": false,

}

