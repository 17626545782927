import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { Button, Table } from "semantic-ui-react";

const GroupItem = ({ id, group, color, role, email, startdate }) => {
  const navigate = useNavigate();

  const onClick = () => {
    if (email) {
      navigate(`/email/${id}`);
    } else {
      navigate(`/${id}`);
    }
  };


  return (

    <Table.Row>
      <Table.Cell>{group.charAt(0).toUpperCase() + group.slice(1)}</Table.Cell>
      <Table.Cell>{DateTime.fromISO(startdate).toFormat('DDD')}</Table.Cell>
      <Table.Cell>{role.charAt(0).toUpperCase() + role.slice(1)}</Table.Cell>
      <Table.Cell>
        <Button fluid size="huge" color="blue" onClick={onClick}>Edit</Button>
      </Table.Cell>
    </Table.Row>
    
  );
};
export default GroupItem;
