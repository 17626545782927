import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Form, Header, Segment } from "semantic-ui-react";
import {
  genGroupPhoto,
  getAllGroups,
  updateBottomText,
} from "../features/groups/groupSlice";
import { getAll } from "../features/members/memberSlice";

/**
 * GenerateGroupPhoto
 * 
 */
const GenerateGroupPhoto = () => {
  const dispatch = useDispatch();
  const { allMembers, isLoading} = useSelector(
    (state) => state.members
  );
  const { groupsArr } = useSelector((state) => state.groups);

  useEffect(() => {
    dispatch(getAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllGroups());
  }, [dispatch]);

  // this creates an array of admin, coordinator, and guest members
  const pocs = allMembers.filter((member) => member.role === "guest" || member.role === "coordinator" || member.role === "admin");

  const [groupID, setGroupID] = useState("");
  // selected POCs
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [subHeading, setSubHeading] = useState("Group Photo");
  const [topHeading, setTopHeading] = useState("University of Calgary FL2F Workshop");
  const [logo1, setLogo1] = useState("https://storage.googleapis.com/staticphotos/fl2f_logo.png");
  const [logo2, setLogo2] = useState("https://storage.googleapis.com/staticphotos/UofCLogo.png");
  

  let groups = groupsArr.filter((group) => !["admin", "facilitator"].includes(group.role));

  console.log("groups", groups); 

  console.log("groups", groupID);

  const [photoTitle, setPhotoTitle] = useState("");

  useEffect(() => {
    if (groupID) {
      const group = groupsArr.find((group) => group.id === groupID);

      const fromattedName = group.groupname.replace(/ /g, "_");

      setPhotoTitle(`${fromattedName}_Group_Photo`);
    }
  }, [groupID, groupsArr]);

  // saveAs is used for downloading the group photo after it has been generated.
  const saveAs = (blob, fileName) => {
    let elem = window.document.createElement("a");
    elem.href = blob;
    elem.download = fileName;
    elem.style = "display:none;";
    (document.body || document.documentElement).appendChild(elem);
    if (typeof elem.click === "function") {
      elem.click();
    } else {
      elem.target = "_blank";
      elem.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      );
    }
    URL.revokeObjectURL(elem.href);
    elem.remove();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!groupID) {
      return toast.error("Please select a group");
    }
    try {
      setLoading(true);
      new Promise((resolve, reject) => {
        dispatch(updateBottomText({ groupID, bottomText: subHeading }))
          .then(() => {
            return dispatch(
              genGroupPhoto({
                groupID: groupID,
                bottomText: subHeading,
                titleText: topHeading,
                extraPeople: selected,
                today: selectedDate,
                photoTitle: photoTitle,
                logo1: logo1,
                logo2: logo2,
              })
            );
          })
          .then((res) => {
            saveAs(res.payload, `${groupID}-group-photo.png`);
            console.log(res);
            resolve();
            setLoading(false);
            // set everything back to default
            setGroupID("");
            setSelected([]);
            setSelectedDate(new Date().toISOString().split("T")[0]);
            setSubHeading("");
            setTopHeading("");
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error);
            reject(error);
          });
      });
    } catch (error) {
      const message =
        error.response.data.message || error.message || error.toString();
      toast.error(message);
      console.log(message);
    }
  };

  return (
    <Container>
      <Segment loading={isLoading || loading} size="huge" raised padded>
        <Header dividing as="h2" textAlign="center" > Generate Group Photo
          <Header.Subheader>
            Select a group and click submit to generate a group photo.
          </Header.Subheader>
        </Header>
        <Form size="large" onSubmit={onSubmit}>
          <Form.Group widths="equal">
            <Form.Select
              type="text"
              label="Select Group"
              placeholder="Select Group"
              options={groups.map((group) => ({
                key: group.id,
                text: group.groupname,
                value: group.id,
              }))}
              onChange={(e, { value }) => setGroupID(value)}
              value={groupID}
              required
            />
            <Form.Input
              type="date"
              label="Ceremony Date"
              placeholder="Ceremony Date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Input
            label="Photo Title, e.g. 'FL2F Workshop'"
            placeholder="Enter a title to appear on the photo"
            value={photoTitle}
            onChange={(e) => setPhotoTitle(e.target.value)}
            required
          />
          <Form.Input
            label="Top Heading, e.g. 'University of Calgary FL2F Workshop'"
            placeholder="Enter a top heading to appear above 'Form Lab to Fufillment'"
            value={topHeading}
            onChange={(e) => setTopHeading(e.target.value)}
          />
          <Form.Input
            label="Sub Heading, e.g. 'Group Photo'"
            placeholder="Enter a sub heading to appear under 'Form Lab to Fufillment'"
            value={subHeading}
            onChange={(e) => setSubHeading(e.target.value)}
          />
          <Form.Select
            multiple
            label="Add POCs, Coordinators, Admins, VIPs, and Guest Speakers (if any) - If there is no picture saved, they will not be included in the photo"
            options={pocs.map((poc) => ({
              key: poc.id,
              text: poc.name + " " + poc.lastname,
              value: poc.id,
            }))}
            onChange={(e, { value }) => setSelected(value)}
            value={selected}
            placeholder="Select POCs"
          />
          <Form.Group widths={"equal"}>
            <Form.Input
              label="Logo 1 - Default FL2F Logo"
              placeholder="Enter a URL for the first logo"
              value={logo1}
              onChange={(e) => setLogo1(e.target.value)}
            />
            <Form.Input
              label="Logo 2 - Default UofC Logo"
              placeholder="Enter a URL for the second logo"
              value={logo2}
              onChange={(e) => setLogo2(e.target.value)}
            />
          </Form.Group>
          <Form.Button fluid color="blue" size="big" content="Submit" />
        </Form>
      </Segment>
    </Container>
  );
};
export default GenerateGroupPhoto;
